import * as React from "react";

interface IHtmlContentProps {
  content?: any;
  className?: string;
}

export const HtmlContent = (props: IHtmlContentProps) => (
  <div className={props.className} dangerouslySetInnerHTML={{ __html: props.content }} />
);
