import * as React from "react";
import kebabCase from "lodash.kebabcase";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { HtmlContent } from "../components/HtmlContent";

interface IBlogPostTemplateProps {
  content;
  description;
  tags;
  title;
  helmet?;
}

export const BlogPostTemplate = (props: IBlogPostTemplateProps) => (
  <Layout>
    <Helmet title={props.title}>
      <meta name="description" content={props.description} />
    </Helmet>
    <section>
      {props.helmet || ""}
      <div>
        <div>
          <div>
            <h1>{props.title}</h1>
            <p>{props.description}</p>
            <HtmlContent content={props.content} />
            {props.tags && props.tags.length ? (
              <div>
                <h4>Tags</h4>
                <ul>
                  {props.tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

interface IBlogPostProps {
  data: any;
}

export default (props: IBlogPostProps) => {
  const { markdownRemark: post } = props.data;
  return (
    <BlogPostTemplate
      content={post.html}
      description={post.frontmatter.description}
      helmet={<Helmet title={`${post.frontmatter.title} | Blog`} />}
      tags={post.frontmatter.tags}
      title={post.frontmatter.title}
    />
  );
};

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
